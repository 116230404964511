import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeOnIcon from "@mui/icons-material/VolumeUp";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../assets/logo.jpg";
import UserSvg from "../../assets/avatar.svg";
import dashboardSvg from "../../assets/dashboard.svg";
import routesSvg from "../../assets/routes.svg";
import orderSvg from "../../assets/order.svg";
import jobSvg from "../../assets/jobs.svg";
import categorySvg from "../../assets/category.svg";
import productSvg from "../../assets/product.svg";
import bulkOrderSvg from "../../assets/bulkOrder.svg";
import customerSvg from "../../assets/customer.svg";
import deliveryBoySvg from "../../assets/deliveryBoy.svg";
import deliverySlotSvg from "../../assets/deliverySlot.svg";
import deliveryCharge from "../../assets/deliveryCharge.svg";
import addNewProduct from "../../assets/addNewProduct.svg";
import disableSlot from "../../assets/disableSlot.svg";
import floatingAdjust from "../../assets/floatingAdjust.svg";
import LogFile from "../../assets/logFile.svg";
import admin from "../../assets/admin.svg";
import transaction from "../../assets/transaction.svg";
import Orders from "../../screens/Orders/Orders";
import Products from "../../screens/Products/Products";
import Customers from "../../screens/Customers/Customers";
import {
    Route,
    Router,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import BulkOrders from "../../screens/BulkOrders/BulkOrders";
import DeliverySlot from "../../screens/DeliverySlot/DeliverySlot";
import { getOrders, orderCsvApi } from "../../Apis/orders";
import DeliveryBoy from "../../screens/DeliveryBoy/DeliveryBoy";
import CustomerDetails from "../../screens/CustomerDetails/CustomerDetails";
import Category from "../../screens/Category/Category";
import OrderDetails from "../../screens/OrderDetails/OrderDetails";
import Dashboard from "../../screens/Dashboard/Dashboard";
import DeliveryCharge from "../../screens/DeliveryCharges/DeliveryCharge";
import NewProduct from "../../screens/NewProduct/NewProduct";
import NewDeliveryCharge from "../../screens/New_Delivery_Charge/NewDeliveryCharge";
import DisableSlot from "../../screens/DisableSlots/DisabledSlot";
import IAM from "../../screens/IAM/IAM";
import Page404 from "../../screens/404/404";
import RoutesPage from "../../screens/Routes/RoutesScreen";
import Jobs from "../../screens/jobs/Jobs";
import DeliveryBoyDetails from "../../screens/DeliveryBoy/DeliveryBoyDetails";
import { Icon, Switch, Tooltip } from "@mui/material";
import WalletTransactions from "../../screens/walletTransactions/walletTransactions";
import FloatingAdjust from "../../screens/FloatingAdjust/FloatingAdjust";
import { getDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";
import { Bounce, toast } from "react-toastify";
import DeliveryLogs from "../../screens/deliveryBoyLogs.js/DeliveryBoyLogs";
import DeliveryReport from "../../screens/DeliveryBoy/DeliveryReport";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const userPermissions = JSON.parse(localStorage.getItem("permissions"));

// Get today's date
const today = new Date();
const todayDay = today.getDate().toString().padStart(2, "0");
const todayMonth = (today.getMonth() + 1).toString().padStart(2, "0");
const todayYear = today.getFullYear().toString();
const formattedToday = `${todayYear}-${todayMonth}-${todayDay}`;

// Get yesterday's date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const yesterdayDay = yesterday.getDate().toString().padStart(2, "0");
const yesterdayMonth = (yesterday.getMonth() + 1).toString().padStart(2, "0");
const yesterdayYear = yesterday.getFullYear().toString();
const formattedYesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MiniDrawer() {
    const location = useLocation();
    const [selectedContent, setSelectedContent] = useState(location.pathname);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        let permissions = localStorage.getItem("permissions")
            ? JSON.parse(localStorage.getItem("permissions"))
            : null;
        localStorage.removeItem("polling_order_count");
        if (
            !localStorage.getItem("mute") &&
            permissions?.some((permission) => permission.name === "orders")
        ) {
            const interval = setInterval(() => {
                getDataSec(`${baseURL}dashboard/dailyStat`).then((res) => {
                    getDataSec(
                        `${baseURL}dashboard/stats?startDate=${moment(
                            new Date()
                        ).format("YYYY-MM-DD")}&endDate=${moment(
                            new Date()
                        ).format("YYYY-MM-DD")}`
                    ).then((res2) => {
                        if (res.success && res2.report) {
                            const current_order_count = res2.report.grossSales;
                            const polling_order_count = localStorage.getItem(
                                "polling_order_count"
                            );
                            localStorage.setItem(
                                "polling_order_count",
                                current_order_count
                            );
                            if (
                                polling_order_count &&
                                polling_order_count < current_order_count
                            ) {
                                const toastId = toast(
                                    `${res.orderCount} New Order available. Click here to reload`,
                                    {
                                        onClick: () => {
                                            toast.dismiss(toastId);
                                            window.location.reload();
                                        },
                                        position: "top-center",
                                        style: {
                                            textAlign: "center",
                                        },
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "dark",
                                        transition: Bounce,
                                    }
                                );
                                new Audio(
                                    require("../../assets/audio/alert.wav")
                                ).play();
                            }
                        }
                    });
                });
            }, 5000);
            return () => clearInterval(interval);
        }
    }, []);

    const setMenuItem = () => {
        let menuItems = [];
        let permissions = userPermissions;
        if (permissions) {
            if (permissions.some((permission) => permission.name === "admin")) {
                menuItems.push(
                    { title: "Routes", icon: routesSvg, route: "/routes" },
                    { title: "Dashboard", icon: dashboardSvg, route: "/" },
                    { title: "Orders", icon: orderSvg, route: "/orders" },
                    { title: "Jobs", icon: jobSvg, route: "/jobs" },
                    { title: "Products", icon: productSvg, route: "/products" },
                    {
                        title: "New Product",
                        icon: addNewProduct,
                        route: "/newProduct",
                    },
                    {
                        title: "Category",
                        icon: categorySvg,
                        route: "/category",
                    },
                    {
                        title: "Customers",
                        icon: customerSvg,
                        route: "/customers",
                    },
                    {
                        title: "Bulk Orders",
                        icon: bulkOrderSvg,
                        route: "/bulkorders",
                    },
                    {
                        title: "Delivery Partner",
                        icon: deliveryBoySvg,
                        route: "/deliverypartner",
                    },
                    {
                        title: "Delivery Slots",
                        icon: deliverySlotSvg,
                        route: "/deliveryslot",
                    },
                    {
                        title: "Delivery Charges",
                        icon: deliveryCharge,
                        route: "/deliverycharges",
                    },
                    {
                        title: "Disable Slots",
                        icon: disableSlot,
                        route: "/disableslot",
                    },
                    { title: "Account", icon: admin, route: "/iam" },
                    {
                        title: "Floating Adjust",
                        icon: floatingAdjust,
                        route: "/floatingadjust",
                    },
                    {
                        title: "Delivery Logs",
                        icon: LogFile,
                        route: "/delivery/logs",
                    },
                    {
                        title: "Wallet Transactions",
                        icon: transaction,
                        route: "/wallet",
                    }
                );
                return setMenu(menuItems);
            }

            if (
                permissions.some(
                    (permission) =>
                        permission.name === "dashboard" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Dashboard",
                    icon: dashboardSvg,
                    route: "/",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "routes" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Routes",
                    icon: routesSvg,
                    route: "/routes",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "orders" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Orders",
                    icon: orderSvg,
                    route: "/orders",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "jobs" && permission.read === true
                )
            ) {
                menuItems.push({ title: "Jobs", icon: jobSvg, route: "/jobs" });
            }

            if (
                permissions.some(
                    (permission) =>
                        permission.name === "products" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Products",
                    icon: productSvg,
                    route: "/products",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "newproduct" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Add New Product",
                    icon: addNewProduct,
                    route: "/newProduct",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "category" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Category",
                    icon: categorySvg,
                    route: "/category",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "bulkorder" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Bulk Orders",
                    icon: bulkOrderSvg,
                    route: "/bulkorders",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "customers" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Customers",
                    icon: customerSvg,
                    route: "/customers",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "deliveryboy" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Delivery Boy",
                    icon: deliveryBoySvg,
                    route: "/deliverypartner",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "iam" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Account",
                    icon: admin,
                    route: "/iam",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "deliveryslot" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Delivery Slot",
                    icon: deliverySlotSvg,
                    route: "/deliveryslot",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "deliverycharges" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Delivery Charge",
                    icon: deliveryCharge,
                    route: "/deliverycharges",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "disableslot" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Disable Slot",
                    icon: disableSlot,
                    route: "/disableslot",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "floatingadjust" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Floating Adjust",
                    icon: floatingAdjust,
                    route: "/floatingadjust",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "deliverylogs" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Delivery Logs",
                    icon: LogFile,
                    route: "/delivery/logs",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "wallet" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Wallet Transactions",
                    icon: transaction,
                    route: "/wallet",
                });
            }
        }
        setMenu(menuItems);
    };

    useEffect(() => {
        setMenuItem();
    }, []);
    const navigate = useNavigate();
    const [isMuted, setIsMuted] = useState(
        localStorage.getItem("mute") === "true"
    );
    const toggleMute = () => {
        if (isMuted) {
            localStorage.removeItem("mute");
        } else {
            localStorage.setItem("mute", "true");
        }
        setIsMuted(!isMuted);
        window.location.reload();
    };

    const renderContent = () => {
        return (
            <Routes>
                <Route
                    path="/customerdetails/:id"
                    element={<CustomerDetails />}
                />
                <Route
                    path="/deliverycharges"
                    element={<NewDeliveryCharge />}
                />
                <Route path="/deliveryslot" element={<DeliverySlot />} />
                <Route path="/orderdetails" element={<OrderDetails />} />
                <Route
                    path="/deliverypartner/:phoneNo"
                    element={<DeliveryBoyDetails />}
                />
                <Route path="/deliverypartner" element={<DeliveryBoy />} />
                <Route path="/disableslot" element={<DisableSlot />} />
                <Route path="/newProduct" element={<NewProduct />} />
                <Route path="/bulkorders" element={<BulkOrders />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/products" element={<Products />} />
                <Route path="/floatingadjust" element={<FloatingAdjust />} />
                <Route path="/category" element={<Category />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/wallet" element={<WalletTransactions />} />
                <Route path="/delivery/logs" element={<DeliveryLogs />} />
                <Route path="/delivery/report" element={<DeliveryReport />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/routes" element={<RoutesPage />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/iam" element={<IAM />} />
                <Route path="/404" element={<Page404 />} />
            </Routes>
        );
    };
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            {/* <CssBaseline /> */}
            <AppBar elevation={0} position="fixed" open={open}>
                <Toolbar
                    style={{
                        backgroundColor: "white",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                color: "#696969",
                                boxShadow: "none",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            style={{ height: 40, width: 40 }}
                            src={logo}
                            id="logo"
                            alt="AkbLogo"
                        />
                        <div style={{ width: 20 }} />
                        <Typography
                            onClick={() => {
                                if (localStorage.getItem("mute") === "true") {
                                    if (window.confirm("Unmute site?")) {
                                        if (window.confirm("Are you sure?"))
                                            localStorage.removeItem("mute");
                                    }
                                } else {
                                    if (window.confirm("Mute site?")) {
                                        if (window.confirm("Are you sure?"))
                                            localStorage.setItem(
                                                "mute",
                                                "true"
                                            );
                                    }
                                }
                            }}
                            color={"black"}
                            variant="h6"
                            noWrap
                            component="div"
                        >
                            Seller Panel <strong>AKB</strong>
                        </Typography>
                    </div>
                    <div>
                        <button
                            style={{
                                cursor: "pointer",
                                backgroundColor: "#00000005",
                                border: "1px solid #0000001A",
                                padding: "12px 25px",
                                borderRadius: "15px",
                            }}
                            onClick={() => {
                                localStorage.removeItem("token2");
                                window.location.reload();
                            }}
                        >
                            Logout
                        </button>
                        <Tooltip
                            title={isMuted ? "Unmute sounds" : "Mute sounds"}
                        >
                            <IconButton
                                sx={{
                                    bgcolor: "#0a3b64",
                                    "&:hover": {
                                        bgcolor: "#0a3b64", // Keeps the background color the same on hover
                                    },
                                }}
                                onClick={toggleMute}
                                color="primary"
                            >
                                {isMuted ? (
                                    <p style={{ fontSize: 10 }}>
                                        <VolumeOffIcon
                                            color="inherit"
                                            sx={{ color: "#ffef03" }}
                                        />
                                    </p>
                                ) : (
                                    <p style={{ fontSize: 10 }}>
                                        <VolumeOnIcon
                                            color="inherit"
                                            sx={{ color: "#ffef03" }}
                                        />
                                    </p>
                                )}
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                style={{ borderRight: "none" }}
                variant="permanent"
                open={open}
            >
                <DrawerHeader
                    sx={{
                        backgroundColor: "white",
                        display: "flex",
                        borderRight: "none",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <div
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            style={{
                                height: 40,
                                width: 40,
                                borderRadius: "50%",
                            }}
                            src={UserSvg}
                            alt="user"
                            id="profileImage"
                        />
                    </div>
                    <div>
                        <div style={{ fontSize: 14 }}>Subodh Avasthi</div>
                        <div style={{ fontSize: 12 }}>Store Manager</div>
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <List style={{ borderRight: "none" }}>
                    {menu?.map((item, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                display: "block",
                                paddingBottom: 0.5,
                            }}
                        >
                            <ListItemButton
                                onClick={() => {
                                    setSelectedContent(item.route);
                                    navigate(item.route, {
                                        state: {
                                            page: 1,
                                            snf: {
                                                sortField: "totalOrders",
                                                sortOrder: -1,
                                            },
                                        },
                                    });
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,

                                    backgroundColor:
                                        item.route === selectedContent
                                            ? "#ccc"
                                            : "transparent",
                                    "&:hover": {
                                        backgroundColor:
                                            item.route !== selectedContent
                                                ? "rgba(0,0,0,0.2)    "
                                                : "#ccc",
                                    },
                                }}
                            >
                                <img
                                    alt="hello"
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                    }}
                                    src={item.icon}
                                />
                                <ListItemText
                                    primary={item.title}
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        paddingLeft: 1,
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flex: 1,
                    minWidth: 1000,
                }}
            >
                <DrawerHeader />
                {renderContent()}
            </Box>
        </Box>
    );
}
