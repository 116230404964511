import { useEffect, useState } from "react";
import { getDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";
import { Button, Table, TableCell, TableHead, TableRow } from "@mui/material";
import { formatDate, formatDateIso } from "../../utils/DateHandler";
import DeliveryLogTable from "./DeliveryLogTable";

//show all the transactions of the wallet
const DeliveryLogs = ({}) => {
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const limit = 40;

    const getData = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}delivery/logs?page=${page}&limit=${limit}`
            );
            if (result.success) {
                console.log(result.logs);
                setTransactions((prev) => [...prev, ...result.logs]);
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getData();
    }, [page]);

    return (
        <div
            style={{
                margin: 20,
                padding: 20,
                backgroundColor: "white",
                borderRadius: 20,
            }}
        >
            <h1>Wallet Transactions</h1>
            <DeliveryLogTable logs={transactions} />
            <Button
                onClick={() => {
                    setPage((prev) => prev + 1);
                }}
            >
                Show More
            </Button>
        </div>
    );
};

export default DeliveryLogs;
