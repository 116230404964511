import React, { useEffect, useState } from "react";
import "./CustomerDetails.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { orderDetailsApi } from "../../Apis/orders";
import CustomerOrderCard from "./CustomerOrderCard";
import { getCustomerDetailsApi } from "../../Apis/Customer";
import BackSvg from "../../assets/back.svg";
import { Button, Table, TableCell, TableHead, TableRow } from "@mui/material";
import { formatDateIso } from "../../utils/DateHandler";

const CustomerDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalSum, setTotalSum] = useState(0);
    const navigate = useNavigate();

    const id = location.pathname.split("/")[2];

    const handleBackBtn = () => {
        navigate(-1);
    };
    const [disableButtons, setDisableButtons] = useState(true);

    useEffect(() => {
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (
                perm.some(
                    (permission) =>
                        (permission.name === "customerdetails" &&
                            permission.read === true) ||
                        permission.name === "admin"
                )
            ) {
                if (
                    perm.some(
                        (permission) =>
                            (permission.name === "customerdetails" &&
                                permission.modify === true) ||
                            permission.name === "admin"
                    )
                )
                    setDisableButtons(false);
                else setDisableButtons(true);
            } else {
                navigate("/404");
            }
        }
    }, []);

    useEffect(() => {
        const getData = async () => {
            if (!loading) {
                setLoading(true);
                const result = await getCustomerDetailsApi(id);
                console.log(result);
                if (result.success) {
                    setUserData(result);
                    var sum = 0;
                    result.user?.orders?.forEach((element) => {
                        sum += element.amount;
                    });
                    setTotalSum(Math.floor(sum));
                } else {
                    console.log(result.message);
                }
                setLoading(false);
            }
        };
        getData();
    }, []);
    function convertTimeToIST(zuluTime) {
        return new Date(zuluTime)
            .toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
            .slice(0, 9);
    }

    const exportCustomerDetail = async () => {
        const result = await getCustomerDetailsApi(id);
        if (result.success) {
            var csv =
                "Order ID, Date, Customer Name, Address,Pincode, Transaction Details, Status, Amount, Delivery Date\n";
            result.user.orders.forEach((element) => {
                csv +=
                    element.id +
                    "," +
                    convertTimeToIST(element.date) +
                    "," +
                    element.address.name +
                    "," +
                    element.address.mobileNo +
                    "," +
                    element.address.line1 +
                    element.address.line2 +
                    "," +
                    element.address.fullAddress +
                    "," +
                    element.paymentMode +
                    "," +
                    element.status +
                    "," +
                    element.amount +
                    "," +
                    element.deliveryDate +
                    "\n";
            });
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = `orderDetails_${id}.csv`;
            hiddenElement.click();
        }
    };

    return (
        <div id="CustomerDetailsRootContainer">
            <div id="topBtnsDiv">
                <button id="backBtn" onClick={handleBackBtn}>
                    <img src={BackSvg} style={{ width: 20, height: 20 }} /> Back
                </button>
                <button id="DownloadOrderBtn" onClick={exportCustomerDetail}>
                    Data Export
                </button>
            </div>

            <div id="CustomerDetailsContainer">
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <div style={{ display: "flex", gap: 20 }}>
                        <h2>{userData?.user.phoneNo}</h2>
                        <h2>
                            {userData?.user.name ? userData.user.name : "N/A"}
                        </h2>
                    </div>
                    <span>Total Spends</span>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <div style={{ display: "flex", gap: 20 }}>
                        <h4>
                            Total Orders:{" "}
                            <span>{userData?.user?.orders?.length}</span>
                        </h4>
                        <h4>
                            Account Created:{" "}
                            <span>{convertTimeToIST(userData?.user.date)}</span>
                        </h4>
                        <h4>
                            Account Disabled:{" "}
                            <span>
                                {userData?.user?.isDisabled?.toString()}
                            </span>
                        </h4>
                        <h4>
                            Account Soft Deleted:{" "}
                            <span>
                                {userData?.user?.isSoftDelete?.toString()}
                            </span>
                        </h4>
                        <h4>
                            OTP: <span>{userData?.user?.otp}</span>
                        </h4>
                        {userData?.user?.referredBy && (
                            <Button
                                onClick={() => {
                                    navigate(
                                        `/customerdetails/${userData.user?.referredBy}`
                                    );
                                    window.location.reload();
                                }}
                            >
                                Referred By
                            </Button>
                        )}
                    </div>
                    <h4> ₹ {totalSum}</h4>
                </div>
            </div>

            <div id="orderList">
                <h1>Order Details</h1>
                <table>
                    <thead className="headerRow">
                        <tr>
                            <th>Sr</th>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Customer Details</th>
                            <th>Transaction Details</th>
                            <th>Status</th>
                            <th>Pending</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData?.user?.orders?.map((item, index) => (
                            <CustomerOrderCard index={index} item={item} />
                        ))}
                    </tbody>
                </table>
                {!userData?.user?.hasOwnProperty("orders") && (
                    <div>No orders found</div>
                )}
            </div>

            <div id="orderList">
                <h1>People Referred</h1>
                <table>
                    <thead className="headerRow">
                        <tr>
                            <th>S. No</th>
                            <th>Name</th>
                            <th>PhoneNumber</th>
                            <th>Referred On</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData?.user?.peopleReferred?.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.phoneNo}</td>
                                <td>{new Date(item.date).toLocaleString()}</td>
                                <td>
                                    <Link
                                        onClick={() => {
                                            navigate(
                                                `/customerdetails/${item._id}`
                                            );
                                            window.location.reload();
                                        }}
                                    >
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {!userData?.user?.peopleReferred.length && (
                    <div>No People Referred</div>
                )}
            </div>

            <div id="orderList">
                <h1>Wallet Transactions</h1>
                <Table style={{ backgroundColor: "white", borderRadius: 20 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Transaction ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Transaction Type</TableCell>
                            <TableCell>Transaction Date</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                        {userData?.user?.walletTransactions.length &&
                            userData?.user?.walletTransactions.map(
                                (transaction, index) => (
                                    <TableRow
                                        key={index}
                                        style={{
                                            backgroundColor:
                                                transaction.type === "debit"
                                                    ? "rgba(255,220,220,0.7)"
                                                    : "rgba(220,255,220,0.7)",
                                        }}
                                    >
                                        <TableCell>{transaction.id}</TableCell>
                                        <TableCell>
                                            {transaction.type === "debit"
                                                ? -transaction.amount
                                                : transaction.amount}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.type}
                                        </TableCell>
                                        <TableCell>
                                            {formatDateIso(transaction.date)}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.message}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                flexDirection: "column",
                                                display: "flex",
                                                gap: 5,
                                            }}
                                        >
                                            {transaction.orderId && (
                                                <Button
                                                    style={{
                                                        borderColor: "black",
                                                        color: "black",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = `/orderdetails?id=${transaction.orderId}`;
                                                    }}
                                                    variant="outlined"
                                                >
                                                    View order
                                                </Button>
                                            )}
                                            {transaction.userId && (
                                                <Button
                                                    style={{
                                                        borderColor: "black",
                                                        color: "black",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = `/customerdetails/${transaction.userId}`;
                                                    }}
                                                    variant="outlined"
                                                >
                                                    View Customer
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                    </TableHead>
                </Table>
            </div>
        </div>
    );
};

export default CustomerDetails;
