import React from "react";
import { Button, Table, TableCell, TableHead, TableRow } from "@mui/material";

const DeliveryLogTable = ({ logs, isDetailPage }) => {
    return (
        <Table style={{ backgroundColor: "white", borderRadius: 20 }}>
            <TableHead>
                <TableRow>
                    <TableCell>Message</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Transaction Date</TableCell>
                    <TableCell>By admin</TableCell>
                    {!isDetailPage && (
                        <>
                            <TableCell>Delivery Partner Name</TableCell>
                            <TableCell>Delivery Partner Mobile</TableCell>
                            <TableCell>Action</TableCell>
                        </>
                    )}
                </TableRow>
                {logs.length &&
                    logs.map((transaction, index) => (
                        <TableRow
                            key={index}
                            style={{
                                backgroundColor: "rgba(240, 240, 240, 0.7)",
                            }}
                        >
                            <TableCell>{transaction.message}</TableCell>
                            <TableCell>{transaction.type}</TableCell>
                            <TableCell>
                                {new Date(
                                    transaction.createdAt
                                ).toLocaleString()}
                            </TableCell>
                            <TableCell>
                                {transaction.admin
                                    ? transaction.admin?.toString()
                                    : "false"}
                            </TableCell>
                            {!isDetailPage && (
                                <>
                                    <TableCell>
                                        {transaction.deliveryPartner?.name}
                                    </TableCell>
                                    <TableCell>
                                        {transaction.deliveryPartner?.phoneNo}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            flexDirection: "column",
                                            display: "flex",
                                            gap: 5,
                                        }}
                                    >
                                        {transaction.deliveryPartner
                                            ?.phoneNo && (
                                            <Button
                                                style={{
                                                    borderColor: "black",
                                                    color: "black",
                                                }}
                                                onClick={() => {
                                                    window.location.href = `/deliverypartner/${transaction.deliveryPartner?.phoneNo}`;
                                                }}
                                                variant="outlined"
                                            >
                                                View Partner
                                            </Button>
                                        )}
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    ))}
            </TableHead>
        </Table>
    );
};

export default DeliveryLogTable;
