import React, { useEffect } from "react";
import "./IAM.css";
import AlertDialogSlide from "../DeliverySlot/SlotPopup";
import {
    changeActiveIAM,
    createUser,
    getIAMUser,
    updateUser,
} from "../../Apis/IAM";
import { toast } from "react-toastify";
import { create } from "@mui/material/styles/createTransitions";
import { useNavigate } from "react-router-dom";

const defaultPerm = [
    { title: "Dashboard", name: "dashboard", read: false, modify: false },
    { title: "Orders", name: "orders", read: false, modify: false },
    { title: "Products", name: "products", read: false, modify: false },
    { title: "Customers", name: "customers", read: false, modify: false },
    { title: "Bulk Order", name: "bulkorder", read: false, modify: false },
    { title: "Accounts and Setting", name: "iam", read: false, modify: false },
    {
        title: "Delivery Charges",
        name: "deliverycharges",
        read: false,
        modify: false,
    },
    {
        title: "Customer Details",
        name: "customerdetails",
        read: false,
        modify: false,
    },
    {
        title: "Delivery Slot",
        name: "deliveryslot",
        read: false,
        modify: false,
    },
    {
        title: "Order Details",
        name: "orderdetails",
        read: false,
        modify: false,
    },
    { title: "Delivery Boy", name: "deliveryboy", read: false, modify: false },
    { title: "Category", name: "category", read: false, modify: false },
    { title: "New Product", name: "newproduct", read: false, modify: false },
    { title: "Routes", name: "routes", read: false, modify: false },
    { title: "Jobs", name: "jobs", read: false, modify: false },
    { title: "Disable Slot", name: "disableslot", read: false, modify: false },
    { title: "Admin", name: "admin", read: false, modify: false },
    {
        title: "Delivery Logs",
        name: "deliverylogs",
        read: false,
        modify: false,
    },
    { title: "Wallet", name: "wallet", read: false, modify: false },
];

const IAM = () => {
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [showmodal, setShowModal] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState("");
    const [userId, setUserId] = React.useState("");
    const [Password, setPassword] = React.useState(null);
    const [ConfirmPassword, setConfirmPassword] = React.useState(null);
    const [newUsr, setNewUsr] = React.useState(false);
    const navigate = useNavigate();
    const [disableButtons, setDisableButtons] = React.useState(true);

    useEffect(() => {
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (
                perm.some(
                    (permission) =>
                        (permission.name === "iam" &&
                            permission.read === true) ||
                        permission.name === "admin"
                )
            ) {
                if (
                    perm.some(
                        (permission) =>
                            (permission.name === "iam" &&
                                permission.modify === true) ||
                            permission.name === "admin"
                    )
                )
                    setDisableButtons(false);
                else setDisableButtons(true);
            } else {
                navigate("/404");
            }
        }
    }, []);

    const [permissions, setPermissions] = React.useState(defaultPerm);

    const fetchUsers = async () => {
        setUsers([]);
        const users = await getIAMUser();
        if (users !== null) setUsers(users);
    };
    React.useEffect(() => {
        fetchUsers();
    }, []);
    async function applyPermissions(item) {
        let temp = [];
        let updatedPermissions = [];
        if (item.permissions[0].name === "admin") {
            temp = [];
        } else {
            temp = defaultPerm;
            updatedPermissions = temp.map((permission) => {
                const matchingPermission = item.permissions.find(
                    (itemPermission) => itemPermission.name === permission.name
                );
                if (
                    matchingPermission &&
                    (matchingPermission.read || matchingPermission.modify)
                ) {
                    return {
                        ...permission,
                        read: matchingPermission.read,
                        modify: matchingPermission.modify,
                    };
                }
                return permission;
            });
        }
        setPermissions(updatedPermissions);
    }

    const saveUserDetails = async () => {
        if (Password !== ConfirmPassword) {
            toast.error("Password and Confirm Password do not match");
            return;
        }
        let temp = {
            id: selectedId,
            userId,
            password: Password,
            permissions: permissions,
        };
        try {
            if (newUsr) {
                const res = await createUser(temp);
                if (res) {
                    toast.success("User created successfully");
                    fetchUsers();
                    setShowModal(false);
                }
            } else {
                const res = await updateUser(temp);
                if (res) {
                    toast.success("User updated successfully");
                    fetchUsers();
                    setShowModal(false);
                }
            }
        } catch (e) {
            console.log(e);
            toast.error("Failed to update user");
        }
    };

    const mapUsers = () => {
        return users?.map((user, index) => {
            return (
                <tr>
                    <td>{index + 1}</td>
                    <td>{user.userId}</td>
                    <td>{user.created}</td>
                    <td>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={user.isActive}
                                onClick={async () => {
                                    const res = await changeActiveIAM(
                                        user._id,
                                        !user.isActive
                                    );
                                    if (res) {
                                        fetchUsers();
                                        toast.success(
                                            "User status changed successfully"
                                        );
                                    } else {
                                        toast.error(
                                            "Failed to change user status"
                                        );
                                    }
                                }}
                            />
                            <span className="slider"></span>
                        </label>
                    </td>
                    <td>
                        <button
                            onClick={async () => {
                                await applyPermissions(user);
                                setSelectedId(user._id);
                                setUserId(user.userId);
                                setPassword("");
                                setConfirmPassword("");
                                setShowModal(true);
                                setNewUsr(false);
                            }}
                        >
                            Edit
                        </button>
                    </td>
                </tr>
            );
        });
    };

    const handleChangePerm = (name, read, modify) => {
        setPermissions(
            permissions.map((permission) => {
                if (permission.name === name) {
                    return {
                        ...permission,
                        read: read,
                        modify: modify,
                    };
                }
                return permission;
            })
        );
    };

    return (
        <div>
            <AlertDialogSlide open={showmodal} setOpen={setShowModal}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        margin: "10px 0px",
                        gap: 30,
                    }}
                >
                    <input
                        style={{ padding: "10px 10px" }}
                        type="text"
                        placeholder="Username"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                    <input
                        style={{ padding: "10px 10px" }}
                        type="password"
                        placeholder="Enter New Password"
                        value={Password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        style={{ padding: "10px 10px" }}
                        type="text"
                        placeholder="Confirm New Password"
                        value={ConfirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h3>Set User Permissions</h3>
                        <div
                            style={{
                                display: "flex",
                                gap: 20,
                                alignItems: "center",
                            }}
                        >
                            <button
                                onClick={() => setShowModal(false)}
                                style={{
                                    backgroundColor: "#E21B1B1A",
                                    border: "1px solid #E21B1B",
                                    color: "#E21B1B",
                                    padding: "10px 20px",
                                    borderRadius: 16,
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => saveUserDetails()}
                                style={{
                                    backgroundColor: "#0D9E671A",
                                    border: "1px solid #0D9E67",
                                    color: "#0D9E67",
                                    padding: "10px 20px",
                                    borderRadius: 16,
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div>
                        <table>
                            <tbody>
                                {permissions.map((permission, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{permission.title}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={permission?.read}
                                                    onChange={() => {
                                                        handleChangePerm(
                                                            permission.name,
                                                            !permission.read,
                                                            permission.modify
                                                        );
                                                    }}
                                                />
                                                <label>Read</label>
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={permission?.modify}
                                                    onChange={() => {
                                                        handleChangePerm(
                                                            permission.name,
                                                            true,
                                                            !permission.modify
                                                        );
                                                    }}
                                                />
                                                <label>Modify</label>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AlertDialogSlide>

            <div id="changePassDiv">
                <h3>Change Password</h3>
                <div className="line" />
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-around",
                        margin: "30px 20px 30px 20px",
                        gap: 30,
                    }}
                >
                    <input type="password" placeholder="Enter New Password" />
                    <input type="password" placeholder="Confirm New Password" />
                    <button className="SaveBtn">Save</button>
                </div>
            </div>
            {!disableButtons ? (
                <div id="userList">
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h3>User List</h3>
                        <button
                            onClick={() => {
                                setShowModal(true);
                                setSelectedId("");
                                setUserId("");
                                setPassword("");
                                setConfirmPassword("");
                                setNewUsr(true);
                                setPermissions(defaultPerm);
                            }}
                            className="AddUserBtn"
                        >
                            Add User
                        </button>
                    </div>
                    <div className="line" />
                    <table>
                        <thead className="headerRow">
                            <tr>
                                <th>Sr</th>
                                <th>Username</th>
                                <th>Account Created On</th>
                                <th>Role</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>{mapUsers()}</tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default IAM;
