import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseURL } from "../../config/config";
import { getDataSec } from "../../Apis/fun";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers";

const DeliveryReport = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(() => {
        const d1 = new Date("2025-01-01");
        d1.setHours(0, 0, 0, 0);
        return d1;
    });
    const [endDate, setEndDate] = useState(() => {
        return new Date();
    });

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter the data based on the search query
        const filtered = data.filter(
            (deliveryBoy) =>
                deliveryBoy.name.toLowerCase().includes(query) ||
                deliveryBoy.phoneNo.toString().includes(query)
        );
        setFilteredData(filtered);
    };

    const getReport = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}delivery/report?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
            );
            if (result.success) {
                setData(result.data);
                setFilteredData(result.data); // Initialize filtered data
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getReport();
    }, []);

    return (
        <div
            style={{
                // padding: "20px",
                margin: "20px",
                marginTop: 120,
                fontFamily: "Arial, sans-serif",
            }}
        >
            <div
                style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    position: "fixed",
                    // top: 0,
                    left: 85,
                    top: 80,
                    zIndex: 999,
                    backgroundColor: "white",
                    width: "calc(100% - 125px)",
                    padding: 10,
                    border: "1px solid #ddd",
                    borderRadius: 12,
                    gap: 30,
                }}
            >
                {/* Date Pickers */}
                <div>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                        Start Date:
                    </label>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                    />
                </div>
                <div>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                        End Date:
                    </label>
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                    />
                </div>

                {/* Search Bar */}
                <div style={{ flex: 1 }}>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                        Search:
                    </label>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by name or phone number"
                        style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                        }}
                    />
                </div>

                {/* Get Report Button */}
                <Button
                    onClick={getReport}
                    style={{
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "#ffffff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        alignSelf: "flex-end",
                    }}
                >
                    Get Report
                </Button>
            </div>

            {/* Render Report Data */}
            {filteredData.length > 0 ? (
                <div>
                    {filteredData.map((deliveryBoy, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                marginBottom: "20px",
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                gap: 20,
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <h3 style={{ margin: "10px 0", color: "#333" }}>
                                    {deliveryBoy.name}
                                </h3>
                                <p>Phone: {deliveryBoy.phoneNo}</p>
                                <p>
                                    Balance: ₹
                                    {deliveryBoy.currentBalance.toFixed(0)}
                                </p>
                                <div
                                    style={{
                                        padding: "10px",
                                        background: "#f9f9f9",
                                        display: "flex",
                                        gap: 20,
                                        flexDirection: "column",
                                        marginTop: "10px",
                                    }}
                                >
                                    {deliveryBoy.transactions.map(
                                        (transaction, idx) => (
                                            <div
                                                key={idx}
                                                style={{
                                                    padding: "10px",
                                                    background: "#fff",
                                                    border: "1px solid #ddd",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <p>
                                                    Type: {transaction.remarks}
                                                </p>
                                                <p>
                                                    Count:{" "}
                                                    {
                                                        transaction.totalTransactions
                                                    }
                                                </p>
                                                <p>
                                                    Amount: ₹
                                                    {transaction.totalAmount}
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>

                            {/* <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 20,
                                }}
                            >
                                {deliveryBoy.attendance?.map((day, idx) => (
                                    <div
                                        key={idx}
                                        style={{
                                            padding: "10px",
                                            background: "#f9f9f9",
                                            border: "1px solid #ddd",
                                            borderRadius: "5px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <p>Date: {day.date}</p>
                                        <p>
                                            OnlineTime:{" "}
                                            {(day.onlineTime / 60).toFixed(0)}{" "}
                                            hrs {day.onlineTime % 60} mins
                                        </p>
                                    </div>
                                ))}
                            </div> */}
                            <div style={{ flex: 1 }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateCalendar
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        disabled
                                        slots={{
                                            day: (props) => {
                                                const {
                                                    day,
                                                    outsideCurrentMonth,
                                                    ...other
                                                } = props;

                                                const date =
                                                    day.format("YYYY-MM-DD");
                                                let attendance = 0;
                                                const dayData =
                                                    deliveryBoy.attendance?.find(
                                                        (day) =>
                                                            day.date === date
                                                    );
                                                attendance =
                                                    dayData?.onlineTime;
                                                if (!dayData) {
                                                    other.style = {
                                                        ...other.style,
                                                        backgroundColor:
                                                            "rgba(222, 222, 222, 0.51)",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "5px",
                                                        // marginTop: "10px",
                                                    };
                                                } else {
                                                    other.style = {
                                                        ...other.style,
                                                        backgroundColor:
                                                            attendance <= 0
                                                                ? "rgba(255, 95, 95, 0.7)"
                                                                : attendance <
                                                                  10 * 60
                                                                ? "rgb(255, 255, 122)"
                                                                : "rgb(142, 255, 122)",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "5px",
                                                        // marginTop: "10px",
                                                    };
                                                }
                                                return (
                                                    <div style={{ width: 100 }}>
                                                        <PickersDay
                                                            day={day}
                                                            outsideCurrentMonth={
                                                                outsideCurrentMonth
                                                            }
                                                            {...other}
                                                        />
                                                        {attendance > 0 && (
                                                            <p>
                                                                {Math.floor(
                                                                    attendance /
                                                                        60
                                                                )}
                                                                h{" "}
                                                                {attendance %
                                                                    60}
                                                                m
                                                            </p>
                                                        )}
                                                    </div>
                                                );
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>
                    No data available for the selected date range or search
                    query.
                </p>
            )}
        </div>
    );
};

export default DeliveryReport;
