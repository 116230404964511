import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Button,
} from "@mui/material";
import { OnlinePrediction } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { formatIndian } from "../../utils/toast";

const SortableTable = ({ deliveryBoyList }) => {
    const [order, setOrder] = useState("asc"); // Sorting order: "asc" or "desc"
    const [orderBy, setOrderBy] = useState(""); // Column to sort by
    const navigate = useNavigate();

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const sortedData = deliveryBoyList.slice().sort((a, b) => {
        if (!orderBy) return 0;

        let aValue = a[orderBy];
        let bValue = b[orderBy];

        // Handle date fields specifically
        if (["onlineTime", "tncAcceptedDate"].includes(orderBy)) {
            aValue = new Date(aValue);
            bValue = new Date(bValue);
        }

        if (aValue < bValue) return order === "asc" ? -1 : 1;
        if (aValue > bValue) return order === "asc" ? 1 : -1;
        return 0;
    });

    return (
        <TableContainer
            component={Paper}
            style={{ padding: 20, borderRadius: 12, backgroundColor: "white" }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {[
                            { id: "index", label: "Sr No.", disableSort: true },
                            { id: "name", label: "Name" },
                            { id: "phoneNo", label: "Phone No." },
                            { id: "currentBalance", label: "Current Balance" },
                            { id: "floatingCash", label: "Floating Cash" },
                            { id: "ranking", label: "Ranking" },
                            { id: "onlineTime", label: "Last Online Time" },
                            { id: "tncAcceptedDate", label: "Joining Date" },
                            { id: "status", label: "Status" },
                            {
                                id: "action",
                                label: "Action",
                                disableSort: true,
                            },
                        ].map((headCell) => (
                            <TableCell key={headCell.id}>
                                {headCell.disableSort ? (
                                    headCell.label
                                ) : (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={
                                            orderBy === headCell.id
                                                ? order
                                                : "asc"
                                        }
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData && sortedData.length ? (
                        sortedData.map((deliveryBoy, index) => (
                            <TableRow
                                key={deliveryBoy.phoneNo}
                                style={{
                                    border:
                                        deliveryBoy.onlineTime &&
                                        new Date() -
                                            new Date(deliveryBoy.onlineTime) <
                                            24 * 36e5
                                            ? "1px solid green"
                                            : "",
                                }}
                                sx={{ "td, th": { border: "1px solid #ddd" } }}
                            >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{deliveryBoy.name}</TableCell>
                                <TableCell>+91 {deliveryBoy.phoneNo}</TableCell>
                                <TableCell>
                                    Rs{" "}
                                    {formatIndian(deliveryBoy.currentBalance)}
                                </TableCell>
                                <TableCell>
                                    Rs {formatIndian(deliveryBoy.floatingCash)}
                                </TableCell>
                                <TableCell>{deliveryBoy.ranking}</TableCell>
                                <TableCell>{deliveryBoy.onlineTime}</TableCell>
                                <TableCell>
                                    {deliveryBoy.tncAcceptedDate}
                                </TableCell>
                                <TableCell>{deliveryBoy.status}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#eee",
                                            borderRadius: 12,
                                            color: "black",
                                            fontWeight: 500,
                                        }}
                                        onClick={() =>
                                            navigate(
                                                `/deliverypartner/${deliveryBoy.phoneNo}`
                                            )
                                        }
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={10} align="center">
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SortableTable;
