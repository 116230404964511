import React, { useEffect, useState } from "react";
import "./DeliveryBoy.css";
import { toast } from "react-toastify";
import { getDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import SortableTable from "./DeliveryBoyTable";
import { Button } from "@mui/material";

const DeliveryBoy = () => {
    const [deliveryBoyList, setDeliveryBoyList] = useState([]);
    const [PhoneNumber, setPhoneNumber] = useState();
    const navigate = useNavigate();
    const [disableButtons, setDisableButtons] = useState(true);

    const handlePhoneNumber = () => {
        navigate(`/deliverypartner/${PhoneNumber}`);
    };

    const getData = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}deliveryBoy/list?page=0&limit=1000`
            );
            // result.deliveryBoys.map((item, index) => {
            //     let onlineTime = item.onlineTime;
            //     item.onlineTime = new Date(onlineTime).toLocaleString();
            //     item.rawonlineTime = onlineTime;
            //     item.tncAcceptedDate = new Date(item.tncAcceptedDate).toLocaleDateString();
            // })
            setDeliveryBoyList(result.deliveryBoys);
        } catch (err) {
            console.log(err);
            toast.error("Cannot fetch delivery partner data");
        }
    };

    useEffect(() => {
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (
                perm.some(
                    (permission) =>
                        (permission.name === "deliveryboy" &&
                            permission.read === true) ||
                        permission.name === "admin"
                )
            ) {
                if (
                    perm.some(
                        (permission) =>
                            (permission.name === "deliveryboy" &&
                                permission.modify === true) ||
                            permission.name === "admin"
                    )
                )
                    setDisableButtons(false);
                else setDisableButtons(true);
            } else {
                navigate("/404");
            }
        }
        getData();
    }, []);

    return (
        <div
            style={{
                padding: 20,
                gap: 20,
                display: "flex",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    backgroundColor: "white",
                    padding: 20,
                    display: "flex",
                    gap: 20,
                    flex: 1,
                }}
            >
                <input
                    className="searchOrder"
                    onKeyDownCapture={(e) => {
                        if (e.nativeEvent.key === "Enter") {
                            handlePhoneNumber();
                        }
                    }}
                    placeholder="Search by Mobile No"
                    type="text"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button
                    onClick={handlePhoneNumber}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "#ffef03",
                        color: "#000",
                        borderWidth: 1,
                        padding: 10,
                        fontSize: 14,
                        flex: 1,
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderColor: "#e3d400",
                        overflow: "hidden",
                        textAlign: "center",
                        alignItems: "center",
                        maxWidth: 200,
                    }}
                >
                    Search
                </button>
                <Button onClick={() => navigate("/delivery/report")}>
                    View Report
                </Button>
            </div>

            <SortableTable deliveryBoyList={deliveryBoyList} />
        </div>
    );
};

export default DeliveryBoy;
